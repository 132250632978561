<template>
  <div class="ui segment">
    <HelloWorld msg="Manage" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Manage",
  head: {
    title: {
      inner: "Manage"
    }
  },
  components: {
    HelloWorld
  }
};
</script>
