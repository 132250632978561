<template>
  <div class="overviewPage tile is-ancestor" style="flex: 1;">
    <div class="tile is-vertical is-12">
      <div class="tile">
        <div class="tile is-parent is-vertical">
          <article class="tile is-child panel is-white has-background-white">
            <p class="panel-heading">
              Manage
            </p>
            <div class="panel-block">
              <p>
                Manage the flow of your originations and access all valuation
                history in customised data dashboards.
              </p>
              <div class="columns">
                <div class="column">
                  <router-link
                    :to="{
                      name: 'valuation-dashboard',
                      params: { userId: 123 }
                    }"
                  >
                    <b-icon icon="chart-line" />
                    Valuation Dashboard &#8250;</router-link
                  >
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="cog" />
                    Digital Valuations &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="desktop" />
                    Desktop Valuations &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="clipboard-list" />
                    Physical Valuations &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6 coming-soon">
                  <a>
                    <b-icon icon="project-diagram" />
                    Market Insight &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
            </div>
          </article>
          <article class="tile is-child panel is-white has-background-white">
            <p class="panel-heading">
              Optimise
            </p>
            <div class="panel-block">
              <p>
                Optimise your current lending decisions with scenario impact
                tools and a rule change management system. Book dedicated time
                with our analysts.
              </p>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="cogs" />
                    Rule Manager &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="search-dollar" />
                    Market Insight &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="cogs" />
                    Rule Simulator &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="comments" />
                    Ask The Analyst &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="tile is-parent is-vertical">
          <article class="tile is-child panel is-white has-background-white">
            <p class="panel-heading">
              Monitor
            </p>
            <div class="panel-block">
              <p>
                Monitor your current and historic portfolio assessments,
                identify trends, track risk indicators and surface
                opportunities.
              </p>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="coins" />
                    Portfolio &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="globe-europe" />
                    Demographics &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="chart-bar" />
                    Benchmarking &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="cloud-sun-rain" />
                    Climate Change &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
              <div class="columns">
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="bullseye" />
                    Concentration &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
                <div class="column coming-soon">
                  <a>
                    <b-icon icon="handshake" />
                    Forced Sale &#8250;
                  </a>
                  <span class="tag is-small">COMING SOON</span>
                </div>
              </div>
            </div>
          </article>
          <article class="tile is-child panel is-white has-background-white">
            <p class="panel-heading">
              Latest News
            </p>
            <div class="panel-block">
              <p>
                Download the latest market reports.
              </p>
              <div class="box has-background-info-light is-shadowless">
                UK Rental Market Report
                <div class="tag is-warning is-light is-pulled-right">
                  COMING SOON
                </div>
              </div>
              <div class="box has-background-info-light is-shadowless">
                UK House Price Index
                <div class="tag is-warning is-light is-pulled-right">
                  COMING SOON
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Overview",
  head: {
    title: {
      inner: "Overview"
    }
  },
  components: {}
};
</script>
<style scoped lang="scss">
.panel-block {
  display: block;
  padding: 0.75em 1.3em;

  p {
    padding-bottom: 20px;
  }
}

.tag.is-small {
  font-size: 0.5rem;
  vertical-align: middle;
}

.coming-soon {
  opacity: 0.3;
}
</style>
