<template>
  <div class="box">
    <div class="has-text-danger has-text-centered">
      <b-icon icon="ban" size="is-large"></b-icon>
    </div>
    <h1 class="title is-4 has-text-centered">
      Access Denied!
    </h1>
    <p class="has-text-centered">
      You do not have permissions to access the requested page. You can log out
      and then log in with an appropriate user account.
    </p>
    <div class="columns pt-5">
      <!--<div class="column">
        <b-button
          type="is-primary"
          icon-left="home"
          expanded
          tag="router-link"
          to="/overview"
        >
          Overview</b-button
        >
      </div>-->
      <div class="column has-text-centered">
        <b-button class="green-button"
          type="is-primary"                
          icon-left="sign-out-alt"          
          @click="logout"
        >
          Logout</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types.js";
export default {
  name: "RestrictedAccess",
  head: {
    title: {
      inner: "Restricted Access"
    }
  },
  components: {},
  methods: {
    logout() {
      this.$store.dispatch(types.LOGOUT_ADD_AND_POWERBI);
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  max-width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .icon.is-large {
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
  }
    
    .green-button {
        cursor: pointer;
        border-radius: 0.625rem;
        border-width: 2px;
        --tw-border-opacity: 1;
        border-color: rgb(33 150 83 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(33 150 83 / var(--tw-bg-opacity));
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
    }
}
</style>
