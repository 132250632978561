import Vue from "vue";

export default class ErrorService {
  constructor() {}

  static onWindowError(message, source, line, column, error) {
    var customProperties = {
      message: message,
      source: source,
      line: line,
      column: column
    };
    Vue.appInsights.appInsights.trackException(error, customProperties);
  }

  static onError(error) {
    Vue.appInsights.trackException(error);
    console.log(error);
  }

  static onWarn(msg, vm, trace) {
    var warnObj = {
      Error: msg,
      Description: trace
    };
    Vue.appInsights.trackEvent(
      { name: "Vue & JavaScript Warning" },
      { value: warnObj }
    );
    console.log(msg);
  }

  static onWarnAxios(error) {
    var warnObj = {
      Error: error.message,
      Description: error.config
    };

    Vue.appInsights.trackEvent(
      { name: "Vue Axios Problem" },
      { value: warnObj }
    );
    Vue.appInsights.trackException(error);
    console.log(error);
  }

  static onInfo(info) {
    Vue.appInsights.trackEvent(info);
  }
}
