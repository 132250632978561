let ThemesShowcaseState = {
  themesArray: null,
  themesReport: null,
  dataColorSize: 16,
  backgroundSize: 16
};

// For report themes documentation please check https://docs.microsoft.com/en-us/power-bi/desktop-report-themes
const jsonThemes = [
  {
    name: "HSBC",
    dataColors: [
      "#67001f",
      "#b2182b",
      "#d6604d",
      "#f4a582",
      "#92c5de",
      "#4393c3",
      "#2166ac",
      "#053061",
      "#bbbbbb",
      "#999999",
      "#666666",
      "#333333"
    ],
    background: "#FFFFFF",
    foreground: "#67001f",
    tableAccent: "#b2182b"
  },
  {
    name: "BARCLAYS",
    dataColors: [
      "#00B7C3",
      "#505C6D",
      "#A7ADB6",
      "#008089",
      "#99E2E7",
      "#C9C472",
      "#88CE81",
      "#E97768",
      "#4CB1E7",
      "#858D99",
      "#D3D6DA",
      "#59CCB4",
      "#75D8E7",
      "#D9D47A",
      "#E89E63",
      "#DBBDEB",
      "#6A7483",
      "#39B294",
      "#73BA5A",
      "#E65E6D"
    ],
    background: "#FFFFFF",
    foreground: "#000000",
    tableAccent: "#00B7C3"
  }
];

var jsonThemesList = () => {
  return jsonThemes;
};

var initPowerBi = (embedContainer, accessToken, type, embedUrl, themeName) => {
  let pickedTheme = jsonThemes.find(el => el.name == themeName);

  //https://blog.christophe-henry.dev/2019/01/09/Mock-the-Vue-module-with-Jest.html
  const pbi = require("powerbi-client");

  var config = {
    type,
    accessToken,
    embedUrl,
    tokenType: pbi.models.TokenType.Embed,
    permissions: pbi.models.Permissions.All,
    settings: {
      background: pbi.models.BackgroundType.Transparent,
      filterPaneEnabled: true,
      navContentPaneEnabled: true
    },
    theme: { themeJson: pickedTheme }
  };

  let powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  powerbi.reset(embedContainer);

  ThemesShowcaseState.themesReport = powerbi.embed(embedContainer, config);
};

var reloadTheme = themeName => {
  let pickedTheme = jsonThemes.find(el => el.name == themeName);
  ThemesShowcaseState.themesReport.applyTheme({ themeJson: pickedTheme });
};

//https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embedding-Basic-interactions#print-a-report
var saveReportOnService = () => {
  var saveAsParameters = {
    name: "PowerBiReport"
  };
  ThemesShowcaseState.themesReport.saveAs(saveAsParameters).catch(error => {
    console.log(error);
  });
};

var printReport = () => {
  ThemesShowcaseState.themesReport.print().catch(error => {
    console.log(error);
  });
};

export default {
  saveReportOnService,
  printReport,
  initPowerBi,
  reloadTheme,
  jsonThemesList
};
