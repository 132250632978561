<template>
  <div class="ui segment">
    <HelloWorld msg="Monitor" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Monitor",
  head: {
    title: {
      inner: "Monitor"
    }
  },
  components: {
    HelloWorld
  }
};
</script>
