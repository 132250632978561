<template>
  <div class="homepage">
    <div class="columns is-gapless main-columns">
      <div class="main-col-left column is-7">
        <img
          class="logo"
          src="@/assets/HomeTrack_Dashboard_Logo_RGB_White.svg"
          alt="Home logo"
        />
        <b-tag class="has-text-grey-light beta-tag"> BETA</b-tag>

        <div class="content-box">
          <h1 class="title">The data and analytics platform for lenders</h1>
          <h2 class="subtitle">
            Introducing the property data and analytics platform by Hometrack.
            Risk management, valuation services and market insight for lenders.
          </h2>
          <div>
            <div class="buttons">
              <!-- <b-button type="is-primary" inverted outlined
                  >Create account</b-button
                > -->
              <b-button
                type="is-primary"
                class="loginButton green-button"
                icon-left="chevron-right"
                @click="login"
              >
                Login
              </b-button>
            </div>
          </div>
        </div>

        <footer>
          <span class="has-text-grey-light pr-1">Any questions? </span>
          <a class="has-text-white" href="mailto:customerservice@hometrack.com"
            >Contact Us</a
          >
        </footer>
      </div>

      <div class="main-col-right column is-5">
        <img
          class="faded-logo"
          src="@/assets/HomeTrack_Icon_RGB.svg"
          alt="Hometrack logo"
        />
        <footer class="has-text-grey-light">
          Powered by
          <img src="@/assets/HomeTrack_Logo_RGB-cropped.svg" alt="Hometrack" />
          <br />
          Copyright © 2021 - Hometrack Data Systems Limited - All Rights
          Reserved
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types.js";
import { getInstance } from "@/auth/authWrapper";
//import router from "@/router/index.js";
export default {
  name: "Homepage",
  head: {
    title: {
      inner: "Welcome",
    },
  },
  methods: {
    login() {
      getInstance().loginWithRedirect({
        appState: { targetUrl: "/valuations" },
      });
    },
    logout() {
      this.$store.dispatch(types.LOGOUT_ADD_AND_POWERBI);
    },
  },
  computed: {
    hasWrongStoreState() {
      return this.$store.getters.hasWrongStoreState();
    },
  },
};
</script>

<style scoped lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

.main-columns {
  .column {
    box-sizing: border-box;
    position: relative;

    @include from($tablet) {
      height: 100vh;
      min-height: 705px;
    }
  }

  .main-col-left {
    background: #273646;
    padding: 0 60px !important;

    @include mobile {
      padding: 0 20px !important;
    }

    .logo {
      width: 900px;
      left: 30px;

      margin-top: 35px;
      margin-left: -30px;
    }

    .beta-tag {
      margin-top: 35px;
      position: absolute;
    }

    .content-box {
      @include from($tablet) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .title {
        font-family: "Poppins Semibold";
        font-weight: 500;
        color: white;
        width: 90%;
        margin-bottom: 3rem;

        @include mobile() {
        }

        @include from($tablet) {
          font-size: 45px;
        }

        @include from($desktop) {
          font-size: 55px;
        }

        @include from($fullhd) {
          font-size: 60px;
          line-height: 90px;
        }
      }

      .subtitle {
        font-weight: 200;
        color: white;
        opacity: 0.8;
        text-align: justify;
        margin-bottom: 2.2rem;

        @include mobile() {
          font-size: 16px;
          line-height: 26px;
          width: 80%;
        }

        @include from($tablet) {
          font-size: 17px;
          line-height: 26px;
          width: 80%;
        }

        @include from($desktop) {
          font-size: 19px;
          line-height: 30px;
          width: 70%;
        }

        @include from($fullhd) {
          font-size: 21px;
          line-height: 34px;
          width: 70%;
        }
      }

      .buttons button {
        font-weight: 500;
      }
      .buttons button:first-child {
        margin-right: 2.5rem;
      }
      .button.is-inverted {
        border-width: 2px;
      }
    }

    footer {
      @include from($tablet) {
        position: absolute;
        bottom: 0;
      }
      padding: 40px 0;
      font-size: 12px;
    }
  }

  .green-button {
    cursor: pointer;
    border-radius: 0.625rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(33 150 83 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(33 150 83 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .main-col-right {
    padding: 40px !important;

    .faded-logo {
      display: block;
      position: absolute;
      max-width: 552px;
      padding-left: 60px;
      opacity: 0.18;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      @include mobile {
        width: 205px;
        position: static;
      }
    }

    footer {
      @include from($tablet) {
        position: absolute;
        bottom: 0;
      }
      position: absolute;
      padding: 40px 0;
      text-align: center;
      font-size: 12px;
      left: 0;
      width: 100%;

      img {
        height: 11px;
      }
    }
  }
}
</style>
