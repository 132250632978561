import { getInstance } from "./authWrapper";
import store from "../store";

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = () => {
    if (
      authService.isAuthenticated &&
      store.getters.hasWrongStoreState() == null
    ) {
      if (
        (to.path.toLowerCase() == "/valuations" &&
          !store.getters.hasManagerDashBoardPageAccess()) ||
        (to.path.toLowerCase() == "/monitor" &&
          !store.getters.hasMonitorPageAccess()) ||
        (to.path.toLowerCase() == "/optimise" &&
          !store.getters.hasOptimisePageAccess()) ||
        (to.path.toLowerCase() == "/account-settings" &&
          !store.getters.hasReadProfileAccess())
      ) {
        return next("/restricted-access");
      }
      return next();
    } else if (authService.isAuthenticated &&
      store.getters.hasWrongStoreState() != null) {
      return next("/error");
    } else if (!authService.isAuthenticated) {
      authService.loginWithRedirect({
        appState: { targetUrl: to.path }
      });
    } else return next("/");
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (!loading) {
      return fn();
    }
  });
};
