import { render, staticRenderFns } from "./MalibuOrganisationDropDown.vue?vue&type=template&id=df5db908&scoped=true&"
import script from "./MalibuOrganisationDropDown.vue?vue&type=script&lang=js&"
export * from "./MalibuOrganisationDropDown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df5db908",
  null
  
)

export default component.exports