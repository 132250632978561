<template>
  <router-view />
</template>

<script>
import { getInstance } from "@/auth/authWrapper";

export default {
  components: {
    // Error,
  },

  computed: {
    isAuthenticated() {
      return getInstance().isAuthenticated;
    },
    isUserLoggedWithNoProblems() {
      return (
        getInstance().isAuthenticated &&
        this.$store.getters.hasWrongStoreState() == null
      );
    },
    isLoading() {
      return getInstance().loading;
    }
  }
};
</script>
