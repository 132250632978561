<template>
  <div class="has-aside-left has-aside-expanded">
    <SideBar />
    <TopBar />
    <section class="section main-content">
      <router-view />
    </section>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import TopBar from "@/components/TopBar.vue";
import { getInstance } from "@/auth/authWrapper";

export default {
  components: {
    SideBar,
    TopBar
    // Error,
  },
  computed: {
    isAuthenticated() {
      return getInstance().isAuthenticated;
    },
    isUserLoggedWithNoProblems() {
      return (
        getInstance().isAuthenticated &&
        this.$store.getters.hasWrongStoreState() == null
      );
    },
    isLoading() {
      return getInstance().loading;
    }
  },
  mounted() {
    console.log("Dashboard mounted");
    console.log(this.$store.getters.hasWrongStoreState());
  }
};
</script>

<style lang="scss"></style>
