let dashboard_configuration_url;
let claims_url;
let tableau_token_url;
let app_insights;
let auth0_url;
let auth0_client_id;
let auth0_audience;
let auth0_domain;

if (process.env.NODE_ENV === "production") {
  // set variable values which we will overwrite at runtime in the docker container
  dashboard_configuration_url = "https://api.home.dev.hometrack.com/DashboardConfiguration";
  claims_url = "https://api.home.dev.hometrack.com/userclaims";
  tableau_token_url = "https://api.home.dev.hometrack.com/tableau";
  app_insights = "d7ca3438-63a0-4c4b-9d88-39b2a5571218";
  auth0_url = "https://api.malibu.com";
  auth0_client_id = "5bJWHkPH9h80QOuKZhas9zn1BXlwNrct";
  auth0_audience = "https://hometrack-dev.eu.auth0.com/api/v2/";
  auth0_domain = "login.dev.hometrack.com";
} else {
  // set variable values from the environment
  dashboard_configuration_url = process.env.https://api.home.dev.hometrack.com/DashboardConfiguration;
  claims_url = process.env.https://api.home.dev.hometrack.com/userclaims;
  tableau_token_url = process.env.https://api.home.dev.hometrack.com/tableau;
  app_insights = process.env.d7ca3438-63a0-4c4b-9d88-39b2a5571218;
  auth0_url = process.env.https://api.malibu.com;
  auth0_client_id = process.env.5bJWHkPH9h80QOuKZhas9zn1BXlwNrct;
  auth0_audience = process.env.https://hometrack-dev.eu.auth0.com/api/v2/;
  auth0_domain = process.env.login.dev.hometrack.com;
}

export {
  dashboard_configuration_url,
  claims_url,
  tableau_token_url,
  app_insights,
  auth0_url,
  auth0_client_id,
  auth0_audience,
  auth0_domain
};
