<template>
  <div v-if="isPowerBiReport" id="report-container">
    <PowerBi />
  </div>
  <div v-else id="report-container">
    <Tableau />
  </div>
</template>

<script>
import PowerBi from "@/components/PowerBi.vue";
import Tableau from "@/components/Tableau.vue";
import * as types from "@/store/types.js";

export default {
  name: "Valuations",
  head: {
    title: {
      inner: "Valuations"
    }
  },
  components: {
    PowerBi,
    Tableau
  },
  computed: {
    isPowerBiReport() {
      return this.$store.getters.getSelectedReportType() === types.POWERBI_REPORT_TYPE;
    }
  }
};
</script>

<style lang="scss">
  #report-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
  }
</style>
