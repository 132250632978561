<template>
  <div class="ui container" text-align="left">
    <select class="ui dropdown" v-model="selected">
      <option
        v-for="option in listOfThemes"
        v-bind:key="option.value"
        v-bind:value="option.value"
        >{{ option.text }}</option
      >
    </select>
  </div>
</template>

<script>
import powerBi from "@/services/powerbi.service";
import * as types from "@/store/types.js";
export default {
  name: "MalibuThemeDropDown",
  data() {
    return {
      selected: null
    };
  },
  watch: {
    selected() {
      this.$store.commit(types.STORE_ORGANISATION_THEME, this.selected);
    }
  },
  mounted() {
    this.selected = powerBi.jsonThemesList()[1].name; //TODO DEFAULT VALUE THEME
    this.$store.commit(types.STORE_ORGANISATION_THEME, this.selected);
  },
  computed: {
    listOfThemes() {
      return powerBi
        .jsonThemesList()
        .map(el => ({ value: el.name, text: el.name }));
    }
  }
};
</script>

<style scoped></style>
