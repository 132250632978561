<template>
  <div>
    <div class="card mb-5">
      <header class="card-header is-large">
        <p class="card-header-title">
          Organisation Settings
        </p>
      </header>
      <div class="card-content background-light-shaded">
        <div class="columns">
          <div class="column">
            <b-field label="Company Name">
              <b-input
                v-model="organisationDetails.organisationName"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Company Logo</strong>
                    <br />
                    <small>
                      Recommended Logo Format: Transparent .png or .svg suitable
                      for use on a white background no larger than 500px on the
                      longest side
                    </small>
                  </p>
                </div>
              </div>
              <figure class="media-right company-logo">
                <p class="image is-16by9">
                  <img
                    src="@/assets/lender-logos/hsbc-uk.svg"
                    alt="HSBC UK logo"
                  />
                </p>
              </figure>
              <!-- <div class="media-right">
                <button class="delete"></button>
              </div> -->
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-5">
      <header class="card-header is-large">
        <p class="card-header-title">
          User Settings
        </p>
      </header>
      <div class="card-content background-light-shaded">
        <div class="columns">
          <div class="column">
            <b-field grouped>
              <b-field label="Email Address">
                <b-input
                  type="email"
                  maxlength="50"
                  v-model="userProfile.email"
                  disabled
                >
                </b-input>
              </b-field>
              <b-field label="Full Name" expanded>
                <b-input v-model="fullName" disabled></b-input>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field label="First Name" expanded>
                <b-input v-model="userProfile.given_name" disabled></b-input>
              </b-field>

              <b-field label="Last Name" expanded>
                <b-input v-model="userProfile.family_name" disabled></b-input>
              </b-field>
            </b-field>
          </div>
          <div class="column">
            <div class="card mb-3" v-if="canUpdateEmail">
              <div class="card-header">
                <div class="card-header-title">
                  Update Email Address
                </div>
              </div>
              <div class="card-content background-light-shaded px-3 py-2">
                <b-field grouped label="New Email" custom-class="is-small">
                  <b-input name="new-email" expanded size="is-small"></b-input>
                  <p class="control">
                    <b-button
                      label="Update"
                      type="is-primary"
                      size="is-small"
                      disabled
                    />
                  </p>
                </b-field>
              </div>
            </div>

            <div class="card mb-3" v-if="canUpdatePassword">
              <div class="card-header">
                <div class="card-header-title">
                  Update Password
                </div>
              </div>
              <div class="card-content background-light-shaded px-3 py-2">
                <b-field grouped label="Old Password" custom-class="is-small">
                  <b-input
                    value="123"
                    type="password"
                    maxlength="30"
                    size="is-small"
                    expanded
                  ></b-input>
                  <p class="control">
                    <!-- Adding this dummy button to keep the size of the Old Password input the same as the New Password -->
                    <button class="button is-small is-invisible">Update</button>
                  </p>
                </b-field>
                <b-field grouped label="New Password" custom-class="is-small">
                  <b-input
                    value="123"
                    type="password"
                    maxlength="30"
                    size="is-small"
                    expanded
                  ></b-input>
                  <p class="control">
                    <b-button
                      label="Update"
                      type="is-primary"
                      size="is-small"
                      disabled
                    />
                  </p>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer as-block pt-4 px-5">
        <div class="buttons is-pulled-left mb-2">
          <b-button type="is-light" icon-left="sign-out-alt">
            Logout User</b-button
          >
          <!-- <b-button type="is-danger" icon-left="trash-alt">
            Delete Profile</b-button
          > -->
        </div>
        <div class="buttons is-pulled-right mb-2">
          <b-button tag="router-link" to="/overview">Cancel</b-button>
          <!-- <b-button type="is-primary">Save Changes</b-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSettings",
  head: {
    title: {
      inner: "Account Settings"
    }
  },
  components: {},
  data: function() {
    return {
      organisationDetails: {
        organisationName: "",
        organisationDivision: ""
      },
      userProfile: {
        email: "",
        name: "",
        picture: "",
        given_name: "",
        family_name: ""
      }
    };
  },
  computed: {
    organisation() {
      return this.$store.getters.getChoosenOrganisation();
    },
    canUpdateEmail() {
      return false;
    },
    canUpdatePassword() {
      return false;
    },
    fullName() {
      if (this.userProfile.given_name && this.userProfile.family_name)
        return this.userProfile.given_name + " " + this.userProfile.family_name;
      else if (this.userProfile.name != this.userProfile.email)
        return this.userProfile.name;
      return "";
    }
  },
  mounted() {
    console.log("Account Settings mounted");
    this.userProfile.email = this.$store.state.account.email;
    this.userProfile.name = this.$store.state.account.name;
    this.userProfile.given_name = this.$store.state.account.given_name;
    this.userProfile.family_name = this.$store.state.account.family_name;
    this.userProfile.picture = this.$store.state.account.picture;

    this.organisationDetails.organisationDivision = this.$store.getters.getChoosenOrganisation();
    this.organisationDetails.organisationName = this.organisationDetails.organisationDivision
      .replace("- Default Division", "")
      .trim(); // TODO: Refactor
  }
  // methods: {
  //   updateUserProfile() {
  //     const token = await getInstance().getTokenSilently();
  //     await axios.patch(backend_api_base_url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //   }
  // }
};
</script>

<style scoped lang="scss">
.company-logo {
  width: 160px;
}
</style>
