/* Styles */
import "@/scss/main.scss";

/* FontAwesome Icons */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
Vue.component("vue-fontawesome", FontAwesomeIcon);

/* Core: Vue and Buefy */
import Vue from "vue";
import Buefy from "buefy";

/* Router & Store */
import router from "./router";
import store from "./store";

/* Vue Head */
import VueHead from "vue-head";
Vue.use(VueHead, {
  separator: "|",
  complement: "Home"
});

/* Axios plugin */
import axios from "axios";
import Axios from "./plugins/axios";

/* Auth0 */
import { Auth0Plugin } from "./auth";

/* Other plugins */
import VueMq from "vue-mq";
import ImageUploader from "vue-image-upload-resize";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import VueAppInsights from "vue-application-insights";
/* Vue: Main component */
import App from "./App.vue";

// Registering our own components and services
import "@/plugins/globalComponents.js"; // TODO: do we need this?
import ErrorService from "./services/errorService";

import {
  app_insights,
  auth0_client_id,
  auth0_audience,
  auth0_domain
} from "@/vars.js";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: app_insights,
    maxBatchInterval: 2000
  }
});
appInsights.loadAppInsights();

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas"
});

Vue.use(VueAppInsights, {
  appInsights: appInsights,
  router
});

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity
  }
});

Vue.use(ImageUploader);

Vue.config.productionTip = true;

Vue.use(hljs.vuePlugin);

Vue.use(Auth0Plugin, {
  domain: auth0_domain,
  clientId: auth0_client_id,
  audience: auth0_audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(Axios);

// Configuring the ErrorService
window.onerror = (message, source, line, column, error) =>
  ErrorService.onWindowError(message, source, line, column, error);

Vue.config.errorHandler = error => ErrorService.onError(error);

Vue.config.warnHandler = (msg, vm, trace) =>
  ErrorService.onWarn(msg, vm, trace);

axios.interceptors.request.use(
  response => response,
  error => {
    ErrorService.onWarnAxios(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    ErrorService.onWarnAxios(error);
    return Promise.reject(error);
  }
);

// Vue app initialisation
new Vue({
  router,
  store,
  render: h => h(App),
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  }
}).$mount("#app");
