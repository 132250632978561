import axios from "axios";
import * as types from "./types.js";
import {
  getInstance,
  ROLES_CLAIM_AUTH0,
  GROUPS_CLAIM_AUTH0,
  PERMISSIONS_CLAIM_AUTH0,
  BROWSER_VALUATION_MANAGER_DASHBOARD_PERMISSION,
  BROWSER_MONITOR_PAGE_PERMISSION,
  BROWSER_OPTIMISE_PAGE_PERMISSION,
  READ_USER_PROFILE_PERMISSION,
  EDIT_USER_PROFILE_PERMISSION,
  READ_OWN_ORGANISATION_DATA_PERMISSION,
  READ_ANY_ORGANISATION_DATA_PERMISSION,
  ADMIN_GROUP
} from "../auth/authWrapper";

import router from "@/router/index.js";
import ErrorService from "@/services/errorService";
import { claims_url, dashboard_configuration_url, tableau_token_url } from "@/vars.js";

const dashboardConfigState = {
  type: null,
  accountConfigurations: [],
  powerBiEmbedToken: {
    token: null,
    tokenId: null,
    expiration: null
  },
  tableauServer: null
};

const tableauState = {
  trustedtoken: null
};

const auth0State = {
  account: null,
  claims: null,
  wrongState: null,
  organisationTheme: null,
  choosenOrganisation: null
};

const state = {
  ...dashboardConfigState,
  ...tableauState,
  ...auth0State
};

const mutations = {
  [types.STORE_DASHBOARD_CONFIG](state, data) {
    if (data.powerBiEmbedToken !== null && data.powerBiEmbedToken !== 'undefined') {
      state.powerBiEmbedToken.token = data.powerBiEmbedToken.token;
      state.powerBiEmbedToken.expiration = data.powerBiEmbedToken.expiration;
      state.powerBiEmbedToken.tokenId = data.powerBiEmbedToken.tokenId;
    }
    state.reportType = "report";
    state.accountConfigurations = data.accountConfigurations;
    state.tableauServer = data.tableauServer;
  },
  [types.STORE_TABLEAU](state, data) {
    state.trustedtoken = data;
  },
  [types.STORE_ADD_USER_ACCOUNT](state, account) {
    state.account = account;
  },
  [types.STORE_ADD_USER_CLAIMS](state, claims) {
    state.claims = claims;
  },
  [types.STORE_ORGANISATION_THEME](state, organisationTheme) {
    state.organisationTheme = organisationTheme;
  },
  [types.STORE_CHOOSEN_ORGANISATION](state, choosenOrganisation) {
    state.choosenOrganisation = choosenOrganisation;
  },
  [types.STORE_WRONG_STATE](state, wrongState) {
    state.wrongState = wrongState;
  },
  [types.CLEAR_AUTH0](state) {
    state.choosenOrganisation = null;
    state.account = null;
    state.claims = null;
    state.wrongState = null;
    state.organisationTheme = null;
  },
  [types.CLEAR_POWERBI](state) {
    state.accountConfigurations = [];
    state.reportType = null;
    state.powerBiEmbedToken = {
      token: null,
      tokenId: null,
      expiration: null
    };
  }
};

const getValuesFromBackend = async endpoint => {
  const token = await getInstance().getTokenSilently();
  const { data } = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return data;
};

const postValuesToBackend = async (endpoint, body) => {
  const token = await getInstance().getTokenSilently();
  const { data } = await axios.post(endpoint, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return data;
};


const hasSpecificClaimInSection = (state, section, claimName) => {
  if (state.claims == null) {
    return false;
  }

  var all_roles = state.claims.filter(sec => sec.type === section);
  if (
    all_roles.filter(el => el.value.toLowerCase().includes(claimName)).length ==
    1
  ) {
    return true;
  }
  return false;
};

const getSelectedReportConfig = (state) => {
  return state.accountConfigurations.find(c => c.orgDivisionGroupName === state.choosenOrganisation);
};

// External value from store
const getters = {
  getLoggedAuth0UserAccount: state => () => state.account,
  hasAdminPrivilages: state => () => {
    return hasSpecificClaimInSection(state, ROLES_CLAIM_AUTH0, ADMIN_GROUP);
  },
  hasManagerDashBoardPageAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      BROWSER_VALUATION_MANAGER_DASHBOARD_PERMISSION
    );
  },
  hasMonitorPageAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      BROWSER_MONITOR_PAGE_PERMISSION
    );
  },
  hasOptimisePageAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      BROWSER_OPTIMISE_PAGE_PERMISSION
    );
  },
  hasReadProfileAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      READ_USER_PROFILE_PERMISSION
    );
  },
  hasEditProfileAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      EDIT_USER_PROFILE_PERMISSION
    );
  },
  hasReadOwnOrganisationDataAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      READ_OWN_ORGANISATION_DATA_PERMISSION
    );
  },
  hasReadAnyOrganisationDataAccess: state => () => {
    return hasSpecificClaimInSection(
      state,
      PERMISSIONS_CLAIM_AUTH0,
      READ_ANY_ORGANISATION_DATA_PERMISSION
    );
  },
  getLoggedPowerBiToken: state => () => state.powerBiEmbedToken.token,
  getLoggedPowerBiTokenExpiration: state => () =>
    state.powerBiEmbedToken.expiration,
  getTypeOfPowerBi: state => () => state.reportType.toLowerCase(),
  hasWrongStoreState: state => () => state.wrongState,
  getOrganisationTheme: state => () => state.organisationTheme,
  getReports: state => () => state.accountConfigurations,
  getChoosenOrganisation: state => () => state.choosenOrganisation,
  getSelectedReportType: state => () => {
    var selected_config = getSelectedReportConfig(state);
    return selected_config.reportType;
  },
  getTableauSite: state => () => {
    var selected_config = getSelectedReportConfig(state);
    return selected_config.tableauReportDetails.tableauSite;
  },
  getTableauReportPath: state => () => {
    var selected_config = getSelectedReportConfig(state);
    return selected_config.tableauReportDetails.tableauReportPath;
  },
  getStoredTableauToken: state => () => state.trustedtoken,
  getTableauServer: state => () => state.tableauServer
};

// eslint-disable-next-line no-unused-vars
let PowerBiAutoRefreshTokenTimer;

const internalActions = {
  [types.SET_AUTO_LOGIN_POWERBI]({ dispatch }, expiresOn) {
    const expireIn = Date.parse(expiresOn) - new Date().getTime();
    const safetyInterval = 30 * 1000 * 60; //refresh every 30 minutes
    //PROBLEM SetTimeOut execute ASAP!
    PowerBiAutoRefreshTokenTimer = setTimeout(async () => {
      //TODO some endpoint to get refreshed token, right now mocked by loginPowerBi
      await dispatch(types.LOGIN_POWER_BI);
    }, expireIn - safetyInterval);
  },
  async [types.LOGIN_POWER_BI]({ dispatch, commit }) {
    commit(types.STORE_WRONG_STATE, null);

    try {
      var claims = await getValuesFromBackend(claims_url);
      commit(types.STORE_ADD_USER_CLAIMS, claims);
    } catch (e) {
      dispatch(types.CLEAR_STORE);
      commit(
        types.STORE_WRONG_STATE,
        "Can not get claims from PowerBi backend!"
      );
    }

    try {
      var groups = state.claims.filter(sec => sec.type === GROUPS_CLAIM_AUTH0);

      if (groups.length > 0) {
        var data = await getValuesFromBackend(dashboard_configuration_url);

        if (data.powerBiEmbedToken !== null && data.powerBiEmbedToken !== 'undefined') {
          localStorage.setItem("embed-token", data.powerBiEmbedToken.token);
          localStorage.setItem(
            "embed-token-expiration",
            data.powerBiEmbedToken.expiration
          );
          dispatch(types.SET_AUTO_LOGIN_POWERBI, data.powerBiEmbedToken.expiration);
        }

        commit(types.STORE_DASHBOARD_CONFIG, data);

        //default selected organisation
        let defaultSelectedReport =
          state.accountConfigurations[0].orgDivisionGroupName;
        commit(types.STORE_CHOOSEN_ORGANISATION, defaultSelectedReport);
      }
    } catch (e) {
      dispatch(types.CLEAR_STORE);
      commit(
        types.STORE_WRONG_STATE,
        "Can not get embeded token for PowerBi backend!"
      );
      router.push({ name: "error" });
    }
  },
  [types.CLEAR_STORE]({ commit }) {
    localStorage.removeItem("embed-token");
    localStorage.removeItem("embed-token-expiration");
    commit(types.CLEAR_AUTH0);
    commit(types.CLEAR_POWERBI);
    PowerBiAutoRefreshTokenTimer = null;
  }
};

const externalActions = {
  async [types.TRY_SILENT_LOGIN]({ commit, dispatch }) {
    commit(types.STORE_WRONG_STATE, null);
    try {
      var jwtClaims = await getInstance().getIdTokenClaims();

      if (jwtClaims[GROUPS_CLAIM_AUTH0].length == 0) {
        await getInstance().getTokenSilently({ ignoreCache: true });
      }

      var account = await getInstance().getUser();

      if (account !== null) {
        commit(types.STORE_ADD_USER_ACCOUNT, account);
        await dispatch(types.LOGIN_POWER_BI);
      }

      ErrorService.onInfo({
        name: "User logged in",
        properties: { userName: account.email }
      });
    } catch (e) {
      dispatch(types.CLEAR_STORE);
      commit(
        types.STORE_WRONG_STATE,
        "Could not retrieve authorisation claims from backend."
      );
    }
  },
  [types.LOGOUT_ADD_AND_POWERBI]({ dispatch }) {
    ErrorService.onInfo({
      name: "User logged out",
      properties: { userName: state?.account?.email }
    });
    dispatch(types.CLEAR_STORE);
    getInstance().logout();
    router.push({ path: "/" });
  },
  async [types.SET_TABLEAU_TOKEN]({ commit, dispatch }) {
    try {
      var selected_config = state.accountConfigurations.find(c => c.orgDivisionGroupName === state.choosenOrganisation);
      var tableauSite = selected_config.tableauReportDetails.tableauSite;

      var tableauToken = await postValuesToBackend(tableau_token_url, {
        emailAddress: state.account.email,
        tableauSite: tableauSite,
      });

      commit(types.STORE_TABLEAU, tableauToken);
      return tableauToken;

    } catch (e) {
      dispatch(types.CLEAR_STORE);
      commit(
        types.STORE_WRONG_STATE,
        "Could not retrieve tableau token from backend."
      );
    }
  }
};

export default {
  state: state,
  mutations: mutations,
  actions: { ...externalActions, ...internalActions },
  getters: getters,
  modules: {}
};
