export const LOGIN_POWER_BI = "loginPowerBi";
export const SET_AUTO_LOGIN_POWERBI = "setAutoLoginExecutePowerBi";
export const CLEAR_STORE = "cleanStore";
export const CLEAR_AUTH0 = "clearAUTH0";
export const CLEAR_POWERBI = "clearPowerBi";
export const STORE_DASHBOARD_CONFIG = "storeDashboardConfig";
export const STORE_TABLEAU = "storeTableau";
export const STORE_ADD_USER_ACCOUNT = "storeAUTH0UserAccount";
export const STORE_ADD_USER_CLAIMS = "storeAUTH0UserClaims";
export const STORE_ORGANISATION_THEME = "storeOrganisationTheme";
export const STORE_CHOOSEN_ORGANISATION = "storeChoosenOrganisation";
export const STORE_WRONG_STATE = "storeWrongState";
export const LOGOUT_ADD_AND_POWERBI = "logoutAUTH0AndPowerBi";
export const TRY_SILENT_LOGIN = "trySlientLogin";
export const POWERBI_REPORT_TYPE = "PowerBi";
export const TABLEAU_REPORT_TYPE = "Tableau";
export const SET_TABLEAU_TOKEN = "setTableauToken";
