<template>
  <div class="error-page">
    <div class="columns is-gapless main-columns">
      <div class="main-col-left column is-4">
        <img
          class="logo"
          src="@/assets/HomeTrack_Dashboard_Logo_RGB_White.svg"
          alt="Hometrack Dashboard logo"
        />
        <b-tag class="has-text-grey-light beta-tag">BETA</b-tag>

        <footer>
          <span class="has-text-grey-light pr-1">Any questions? </span>
          <a class="has-text-white" href="mailto:customerservice@hometrack.com"
            >Contact Us</a
          >
        </footer>
      </div>

      <div class="main-col-right column is-8">
        <div class="message-section">
          <div class="section">
            <h1 class="title">Oops... Something went wrong</h1>
            <h2 class="subtitle">
              Looks like something went wrong here or the page you’re looking
              for can’t be found.
            </h2>
            <div class="buttons">
              <b-button
                class="green-button"
                type="is-primary"
                icon-left="home"
                @click="backHome"
              >
                Home
              </b-button>
              <!--<b-button
                v-if="isAuthenticated"
                type="is-primary"
                icon-left="chevron-left"
                @click="backToDashboard"
              >
                Dashboard
              </b-button>-->
              <b-button
                class="green-button"
                v-if="isAuthenticated"
                type="is-primary"
                icon-left="sign-out-alt"
                @click="logout"
              >
                Logout
              </b-button>
            </div>
          </div>
        </div>
        <b-image
          :src="require('@/assets/Error_Page_Image.svg')"
          alt="Error Page Image"
        ></b-image>
        <footer class="has-text-grey-light">
          Powered by
          <img src="@/assets/HomeTrack_Logo_RGB-cropped.svg" alt="Hometrack" />
          <br />
          Copyright © 2021 - Hometrack Data Systems Limited - All Rights
          Reserved
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { getInstance } from "@/auth/authWrapper";
import * as types from "@/store/types.js";
export default {
  name: "ErrorPage",
  head: {
    title: {
      inner: "Error",
    },
  },
  components: {},
  computed: {
    isAuthenticated() {
      return getInstance().isAuthenticated;
    },
  },
  methods: {
    backHome() {
      this.$router.push({ path: "/" });
    },
    backToDashboard() {
      this.$router.push({ path: "/overview" });
    },
    logout() {
      this.$store.dispatch(types.LOGOUT_ADD_AND_POWERBI);
    },
  },
};
</script>

<style scoped lang="scss">
.main-columns {
  .column {
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
  }

  .main-col-left {
    background: #273646;
    padding: 0 60px 0 60px !important;

    .logo {
      width: 500px;
      margin-left: -35px;
      margin-top: 35px;
    }

    .beta-tag {
      margin-top: 35px;
      position: absolute;
    }

    footer {
      position: absolute;
      bottom: 40px;
      font-size: 12px;
    }
  }
  .green-button {
    cursor: pointer;
    border-radius: 0.625rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(33 150 83 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(33 150 83 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .main-col-right {
    .title {
      font-family: "Poppins Semibold";
      color: #1580f7;
    }
    .subtitle {
      color: #273646;
    }

    .message-section {
      margin: 9rem 6rem 0;
    }

    footer {
      position: absolute;
      bottom: 40px;
      text-align: center;
      font-size: 12px;
      left: 0;
      width: 100%;

      img {
        height: 11px;
      }
    }
  }
}
</style>
