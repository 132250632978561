import Homepage from "../views/Homepage.vue";
import ErrorPage from "../views/ErrorPage.vue";
import RestrictedAccess from "../views/RestrictedAccess.vue";
import Overview from "../views/Overview.vue";
import AccountSettings from "../views/AccountSettings.vue";
import Manage from "../views/Manage.vue";
import Monitor from "../views/Monitor.vue";
import Optimise from "../views/Optimise.vue";
import Valuations from "../views/Valuations.vue";
import { authGuard } from "../auth";

const routes = [
  {
    path: "/",
    name: "homepage",
    component: Homepage,
    meta: { layout: "simple-layout" }
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
    // authGuard not required
    meta: { layout: "simple-layout" }
  },
  {
    path: "/restricted-access",
    name: "restricted-access",
    component: RestrictedAccess,
    // authGuard not required
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/overview",
    name: "overview",
    component: Overview,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/account-settings",
    name: "account-settings",
    component: AccountSettings,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/valuations",
    name: "valuations",
    component: Valuations,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/manage",
    name: "manage",
    component: Manage,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/monitor",
    name: "monitor",
    component: Monitor,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "/optimise",
    name: "optimise",
    component: Optimise,
    beforeEnter: authGuard,
    meta: { layout: "dashboard-layout" }
  },
  {
    path: "*",
    redirect: "/error"
  }
];

export default routes;
