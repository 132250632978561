<template>
    <div id="vizContainer"></div>
</template>

<script>
/* global tableau */
var viz;
var viz_options = {
    hideTabs: false,
    hideToolbar: false,
    onFirstInteractive: function () {
        viz.getCurrentUrlAsync().then(function (current_url) {
            console.log(current_url);
        })
        $("#edit").show();
    }
};
var trustedToken;

import $ from 'jquery';
import * as types from "@/store/types.js";

export default {
    name: "Tableau",

    mounted() {
        this.initViz();

        this.$store.watch(
            this.$store.getters.getChoosenOrganisation,
            (organisation, oldOrganisation) => {
                if (organisation != oldOrganisation && organisation != null &&
                    this.$store.getters.getSelectedReportType() === types.TABLEAU_REPORT_TYPE) {
                    this.initViz();
                }
            }
        );

    },
    methods: {
        async getTableauToken() {
            await this.$store.dispatch(types.SET_TABLEAU_TOKEN);
            trustedToken = this.$store.getters.getStoredTableauToken();
        },
        async initViz() {
            await this.getTableauToken();
            var containerDiv = document.getElementById("vizContainer");
            var tableauServer = this.$store.getters.getTableauServer();
            var site = this.$store.getters.getTableauSite();
            var reportPath = this.$store.getters.getTableauReportPath();

            var viz_url = tableauServer + "trusted/" + trustedToken + "/t/" + site + "/" + reportPath;
            this.loadViz(containerDiv, viz_url, viz_options);
        },
        loadViz(containerDiv, url, options) {
            if (viz) {
                viz.dispose();
            }

            viz = new tableau.Viz(containerDiv, url, options);
        }
    }
};
</script>

<style lang="scss">
#vizContainer {
    flex: 1;
    display: flex;
    flex-direction: column;

    iframe {
        width: 100%;
        height: 100%;
        flex: 1;
        border: none;
    }
}
</style>
