import Vue from "vue";

import SimpleLayout from "@/views/layouts/SimpleLayout.vue";
import DashboardLayout from "@/views/layouts/DashboardLayout.vue";

import MalibuOrganisationDropDown from "@/components/elements/MalibuOrganisationDropDown.vue";
import MalibuThemeDropDown from "@/components/elements/MalibuThemeDropDown.vue";

Vue.component("simple-layout", SimpleLayout);
Vue.component("dashboard-layout", DashboardLayout);

Vue.component("malibu-organisation-dropdown", MalibuOrganisationDropDown);
Vue.component("malibu-theme-dropdown", MalibuThemeDropDown);
