<template>
  <b-navbar class="top-menu" shadow="true">
    <template #start> </template>

    <template #end>
      <b-navbar-item tag="div">
        <MalibuOrganisationDropDown />
      </b-navbar-item>
      <!--<b-navbar-item tag="div">
        <img
          class="company-logo"
          src="@/assets/lender-logos/hsbc-uk.svg"
          alt="HSBC UK"
        />
      </b-navbar-item>-->
      <b-navbar-item>
        <b-navbar-dropdown :label="userDisplayName" data-qa-id="acct-dropdown">
          <!--<b-navbar-item tag="router-link" :to="{ name: 'account-settings' }">
            Account Settings
          </b-navbar-item>-->
          <b-navbar-item @click="logout">
            Logout
          </b-navbar-item>
        </b-navbar-dropdown>
        <img class="profile-pic is-rounded" :src="userPicture" />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import * as types from "@/store/types.js";
import MalibuOrganisationDropDown from "@/components/elements/MalibuOrganisationDropDown.vue";
export default {
  name: "TopBar",
  components: {
    MalibuOrganisationDropDown
  },
  computed: {
    user() {
      return this.$store.getters.getLoggedAuth0UserAccount();
    },
    userDisplayName() {
      return this.user
        ? this.user.name
          ? this.user.name
          : this.user.email
        : "";
    },
    userPicture() {
      return this.$store.state.account.picture;
    },
    hasReadProfileAccess() {
      return this.$store.getters.hasReadProfileAccess();
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(types.LOGOUT_ADD_AND_POWERBI);
    },
    redirectToProfile() {
      this.$router.push({ path: "/profile" });
    }
  }
};
</script>

<style scoped></style>
