<template>
  <aside class="sidebar is-expanded">
    <img
      class="logo"
      src="@/assets/HomeTrack_Dashboard_Logo_RGB_STACKED_White.svg"
      alt="Home logo"
    />
    <b-tag class="has-text-grey-light beta-tag">BETA</b-tag>

    <b-menu class="main-menu">
      <!--<b-menu-list>
           <b-menu-item
            icon="home"
            label="Overview"
            tag="router-link"
            :to="{ name: 'overview' }"
            :active="isCurrentRoute('overview')"
          ></b-menu-item>
        </b-menu-list>-->
      <b-menu-list label="Valuation Manager">
        <b-menu-item
          icon="chart-line"
          label="Valuations"
          tag="router-link"
          :to="{ name: 'valuations' }"
          :active="isCurrentRoute('valuations')"
        ></b-menu-item>
        <!--<b-menu-item icon="cog"
                         label="Digital Valuations"
                         disabled></b-menu-item>
            <b-menu-item icon="desktop"
                         label="Desktop Valuations"
                         disabled></b-menu-item>
            <b-menu-item icon="clipboard-list"
                         label="Physical Valuations"
                         disabled></b-menu-item>-->
      </b-menu-list>
      <!--<b-menu-list>
            <template slot="label">
                Monitor
                <b-tag class="is-pulled-right">COMING SOON 2022</b-tag>
            </template>
            <b-menu-item icon="coins"
                         label="Portfolio"
                         disabled="true"></b-menu-item>
            <b-menu-item icon="chart-bar"
                         label="Benchmarking"
                         disabled="true"></b-menu-item>
            <b-menu-item icon="bullseye"
                         label="Concentration"
                         disabled="true"></b-menu-item>
            <b-menu-item icon="globe-europe"
                         label="Demographics"
                         disabled="true"></b-menu-item>
        </b-menu-list>-->
    </b-menu>

    <b-menu class="secondary-menu">
      <b-menu-list>
        <b-menu-item
          icon="sliders-h"
          label="Account Settings"
          tag="router-link"
          disabled="true"
          :to="{ name: 'account-settings' }"
          :active="isCurrentRoute('account-settings')"
        ></b-menu-item>
        <b-menu-item
          icon="sign-out-alt"
          label="Logout"
          @click="logout"
        ></b-menu-item>
        <b-menu-item icon="question-circle" label="Help" disabled></b-menu-item>
        <b-menu-item
          icon="bullhorn"
          label="Leave Feedback"
          disabled
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
  </aside>
</template>

<script>
import * as types from "@/store/types.js";
export default {
  name: "SideBar",
  data() {
    return {
      visible: true,
    };
  },

  watch: {
    $mq() {
      if (this.$mq == "tablet" || this.$mq == "mobile") {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    $route(to, from) {
      if ((this.$mq == "tablet" || this.$mq == "mobile") && to != from) {
        this.visible = false;
      }
    },
  },
  methods: {
    isCurrentRoute(routeName) {
      return this.$route.name == routeName;
    },
    openMenu() {
      if (this.visible) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    logout() {
      this.$store.dispatch(types.LOGOUT_ADD_AND_POWERBI);
    },
  },
  computed: {
    hasManagerDashBoardPageAccess() {
      return this.$store.getters.hasManagerDashBoardPageAccess();
    },
    hasMonitorPageAccess() {
      return this.$store.getters.hasMonitorPageAccess();
    },
    hasOptimisePageAccess() {
      return this.$store.getters.hasOptimisePageAccess();
    },
  },
};
</script>

<style lang="scss"></style>
