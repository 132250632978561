<template>
  <div id="app">
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>

    <component :is="layout" v-if="!isLoading">
      <router-view />
    </component>
  </div>
</template>

<script>
import { getInstance } from "@/auth/authWrapper";

const defaultLayout = "simple-layout";

export default {
  name: "App",
  components: {},
  head: {
    // link tags
    link: [
      {
        rel: "icon",
        href: "/images/favicon-2.png",
        sizes: "16x16",
        type: "image/png"
      }
    ]
  },

  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
    isAuthenticated() {
      return getInstance().isAuthenticated;
    },
    isUserLoggedWithNoProblems() {
      return (
        getInstance().isAuthenticated &&
        this.$store.getters.hasWrongStoreState() == null
      );
    },
    isLoading() {
      return getInstance().loading;
    }
  }
};
</script>

<style></style>
