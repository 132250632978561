import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from "axios";
import * as types from "@/store/types.js";
import store from "@/store";

import { auth0_url, dashboard_configuration_url } from "@/vars.js";

export const GROUPS_CLAIM_AUTH0 = auth0_url + "/claims/groups";
export const PERMISSIONS_CLAIM_AUTH0 = auth0_url + "/claims/permissions";
export const ROLES_CLAIM_AUTH0 = auth0_url + "/claims/roles";

//Permissions
export const BROWSER_VALUATION_MANAGER_DASHBOARD_PERMISSION =
  "browse:valuation_manager_dashboard";
export const BROWSER_MONITOR_PAGE_PERMISSION = "browse:monitor_page";
export const BROWSER_OPTIMISE_PAGE_PERMISSION = "browse:optimise_page";
export const READ_USER_PROFILE_PERMISSION = "read:own_user_profile";
export const EDIT_USER_PROFILE_PERMISSION = "edit:own_user_profile";
export const READ_OWN_ORGANISATION_DATA_PERMISSION =
  "read:data_from_own_organisation_hierarchy";
export const READ_ANY_ORGANISATION_DATA_PERMISSION =
  "read:data_from_any_organisations";

//Groups
export const ADMIN_GROUP = "malibu admin";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      async getUser() {
        return await this.auth0Client.getUser();
      },
      logout(o) {
        return this.auth0Client.logout(o);
      },
      async updateProfile() {
        const token = this.auth0Client.getTokenSilently();
        try {
          const response = await axios.post(dashboard_configuration_url, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          console.log(response);
        } catch (e) {
          let apiMessage = `Error: the server responded with '${e.response.status}: ${e.response.statusText}'`;
          console.log(apiMessage);
        }
      }
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri
      });

      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        if (this.isAuthenticated) {
          await store.dispatch(types.TRY_SILENT_LOGIN);
        }
        this.loading = false;
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
