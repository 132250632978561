<template>
  <div id="powerbi-container" ref="powerBIContainer" class="box"></div>
</template>

<script>
import powerBi from "../services/powerbi.service";
import * as types from "@/store/types.js";

export default {
  name: "PowerBi",
  computed: {
    user() {
      return this.$store.getters.getLoggedAuth0UserAccount();
    },
    isAdmin() {
      return this.$store.getters.hasAdminPrivilages();
    }
  },
  mounted() {
    // Calculating panel size 

    if (this.$store.getters.getLoggedPowerBiToken() != null) {
      this.loadPowerBI(this.$store.getters.getLoggedPowerBiToken());
    } else {
      this.$router.push({ name: "error" });
      return;
    }

    // load powerBI after login: https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    this.$store.watch(
      this.$store.getters.getLoggedPowerBiToken,
      (accessToken, oldAccessToken) => {
        if (accessToken != oldAccessToken && accessToken != null && 
            this.$store.getters.getSelectedReportType() === types.POWERBI_REPORT_TYPE) {
          this.loadPowerBI(accessToken);
        }
      }
    );

    // Reload when the organisation theme is updated
    // this.$store.watch(
    //   this.$store.getters.getOrganisationTheme,
    //   (theme, oldTheme) => {
    //     if (theme != oldTheme && theme != null) {
    //       this.reloadTheme(theme);
    //     }
    //   }
    // );

    this.$store.watch(
      this.$store.getters.getChoosenOrganisation,
      (organisation, oldOrganisation) => {
        if (organisation != oldOrganisation && organisation != null && 
            this.$store.getters.getSelectedReportType() === types.POWERBI_REPORT_TYPE) {
          //FIND SELECTED ORANISATION
          let embedUrl = this.$store.getters
            .getReports()
            .filter(sec => sec.orgDivisionGroupName === organisation)[0]
            .powerBiReportDetails.embedUrl;

          let accessToken = this.$store.getters.getLoggedPowerBiToken();

          let defaultOrganisation = this.$store.getters.getOrganisationTheme();
          let type = this.$store.getters.getTypeOfPowerBi();

          powerBi.initPowerBi(
            this.$refs.powerBIContainer,
            accessToken,
            type,
            embedUrl,
            defaultOrganisation
          );
        }
      }
    );
  },
  methods: {
    loadPowerBI(accessToken) {
      let defaultSelectedReport = this.$store.getters.getChoosenOrganisation();

      let embedUrl = this.$store.getters
        .getReports()
        .filter(sec => sec.orgDivisionGroupName === defaultSelectedReport)[0]
        .powerBiReportDetails.embedUrl;

      let defaultOrganisation = this.$store.getters.getOrganisationTheme();
      let type = this.$store.getters.getTypeOfPowerBi();

      powerBi.initPowerBi(
        this.$refs.powerBIContainer,
        accessToken,
        type,
        embedUrl,
        defaultOrganisation
      );
    },
    reloadTheme(value) {
      powerBi.reloadTheme(value);
    },
    printReport() {
      powerBi.printReport();
    }
  }
};
</script>

<style lang="scss">
#powerbi-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    flex: 1;
    border: none;
  }
}
</style>
