<template>
  <div v-if="hasMoreThenOneReport" data-qa-id="org-dropdown">
    <b-dropdown
      v-model="selectedOrganisation"
      append-to-body
      aria-role="menu"
      scrollable
      max-height="200"
      trap-focus
    >
      <template #trigger>
        <a class="navbar-item" role="button">
          <span>Change Organisation</span>
          <b-icon icon="chevron-down"></b-icon>
        </a>
      </template>

      <b-dropdown-item
        aria-role="listitem"
        v-for="option in organisations"
        v-bind:key="option.value"
        v-bind:value="option.value"
      >
        {{ option.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
//import powerBi from "@/services/powerbi.service";
import * as types from "@/store/types.js";
export default {
  name: "MalibuOrganisationDropDown",
  data() {
    return {
      selectedOrganisation: null
    };
  },
  watch: {
    selectedOrganisation() {
      this.$store.commit(
        types.STORE_CHOOSEN_ORGANISATION,
        this.selectedOrganisation
      );
    }
  },
  mounted() {
    this.selectedOrganisation = this.$store.getters.getChoosenOrganisation();
  },
  computed: {
    organisations() {
      return this.$store.getters.getReports().map(el => ({
        value: el.orgDivisionGroupName,
        text: el.orgDivisionGroupName
      }));
    },
    hasMoreThenOneReport() {
      return this.$store.getters.getReports().length > 1;
    }
  }
};
</script>

<style scoped></style>
