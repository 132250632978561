import Vue from "vue";
import Vuex from "vuex";
import store from "./store.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: store.state,
  mutations: store.mutations,
  actions: store.actions,
  getters: store.getters,
  modules: store.modules
});
